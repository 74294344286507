import React from "react";
import RenderNumbers from "../RenderNumber";

type NumberType = "whatsapp" | "phone";
export type Number = {
  number: string;
  methods: NumberType[];
};

const numbers: Number[] = [
  {
    number: "01228000020",
    methods: ["phone"],
  },{
    number: "01228000020",
    methods: ["phone"],
  },
];

const ContactUs = () => {
  return (
    <div className="w-full">
      <p className="text-[26px] font-[400] absolute top-[15px]">تواصل معنا</p>
      <div className="mt-[10px]">
        <p>
          السادة الأفاضل يسعدنا مساعدتكم وإستقبال مكالمتكم على مدار اليوم من
          الساعة ٩ صباحا حتي ال١٢ صباحاً على الرقم التالي
        </p>

        <div className="flex flex-col gap-[10px] mt-[20px]">
          {numbers.map((number) => (
            <RenderNumbers number={number} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
