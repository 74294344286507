import React from "react";
import cities from "../../Helpers/cities-regions.json";
import CheckBox from "../CheckBox";
import CustomInput from "../CustomInput";
import PhoneInput from "../PhoneInput";
import Select from "../Select";
import { toEnDigit } from "../../Helpers/helperFunctions";
import { Link } from "react-router-dom";

type Props = {
  values: {
    FirstName: string;
    LastName: string;
    Latitude: string;
    Longitude: string;
    MobileNo: string;
    ShopName: string;
    City: string;
    Region: string;
    ChkCalls: string;
    ChkNews: string;
    ChkSMS: string;
  };
  errors: {
    FirstName: string;
    LastName: string;
    Latitude: string;
    Longitude: string;
    MobileNo: string;
    ShopName: string;
    City: string;
    Region: string;
    ChkCalls: string;
    ChkNews: string;
    ChkSMS: string;
  };
  touched: {
    FirstName: boolean;
    LastName: boolean;
    Latitude: boolean;
    Longitude: boolean;
    MobileNo: boolean;
    ShopName: boolean;
    City: boolean;
    Region: boolean;
    ChkCalls: boolean;
    ChkNews: boolean;
    ChkSMS: boolean;
  };
  setFieldTouched: (name: string) => void;
  setFieldValue: (name: string, value: string) => void;
};

function SignupForm({
  errors,
  setFieldValue,
  values,
  setFieldTouched,
  touched,
}: Props) {
  const handlePhoneNumberChange = (value: string) => {
    const convToEn = toEnDigit(value);
    setFieldValue("MobileNo", convToEn);
  };

  return (
    <>
      <div className="text-[34px] font-bold mt-4 text-black-200">
        أهلا بكم في برنامج عروض العالمية لقطع السيارات
      </div>
      <div className="mt-1 flex flex-wrap justify-between">
        <CustomInput
          title={"الاسم الاول"}
          value={values.FirstName}
          onChange={(e) => setFieldValue("FirstName", e)}
          name="FirstName"
          onBlur={() => {
            setFieldTouched("FirstName");
          }}
          errorMessage={
            touched.FirstName && errors.FirstName ? errors.FirstName : ""
          }
        />

        <CustomInput
          title={"الاسم الاخير"}
          name="LastName"
          value={values.LastName}
          onBlur={() => {
            setFieldTouched("LastName");
          }}
          onChange={(e) => setFieldValue("LastName", e)}
          errorMessage={
            touched.LastName && errors.LastName ? errors.LastName : ""
          }
        />

        <CustomInput
          title="اسم المحل"
          value={values.ShopName}
          name="ShopName"
          onBlur={() => {
            setFieldTouched("ShopName");
          }}
          onChange={(e) => setFieldValue("ShopName", e)}
          errorMessage={
            touched.ShopName && errors.ShopName ? errors.ShopName : ""
          }
        />

        <PhoneInput
          title={"رقم الهاتف"}
          value={values.MobileNo}
          onBlur={() => {
            setFieldTouched("MobileNo");
          }}
          onChange={handlePhoneNumberChange}
          errorMessage={
            touched.MobileNo && errors.MobileNo ? errors.MobileNo : ""
          }
        />
        <Select
          title={"المدينة"}
          value={values.City}
          onChange={(val) => setFieldValue("City", val)}
          errorMessage={touched.City && errors.City ? errors.City : ""}
          onBlur={() => {
            setFieldTouched("City");
          }}
          options={cities.map((city) => ({
            title: city.city,
            value: city.city,
          }))}
        />
        <Select
          title={"المنطقة"}
          value={values.Region}
          onBlur={() => {
            setFieldTouched("Region");
          }}
          onChange={(val) => setFieldValue("Region", val)}
          errorMessage={touched.Region && errors.Region ? errors.Region : ""}
          disabled={!values.City}
          options={cities
            ?.find((city) => city.city === values.City)
            ?.regions.map((region) => ({
              title: region,
              value: region,
            }))}
        />
      </div>
      <div className="w-full mt-[23px] flex flex-col">
        <span>الاشتراك والمتابعة</span>
        <CheckBox
          label={"الاتصالات التسويقية"}
          onChange={(val) => setFieldValue("ChkCalls", val)}
          checked={values.ChkCalls}
          mt={20}
        />
        <CheckBox
          label="الأخبار والتحديثات الرسائل القصيرة"
          onChange={(val) => setFieldValue("ChkNews", val)}
          checked={values.ChkNews}
          mt={20}
        />
        <CheckBox
          label="الرسائل القصيرة الخاصة بعملية الإنتاج"
          onChange={(val) => setFieldValue("ChkSMS", val)}
          checked={values.ChkSMS}
          mt={20}
        />
      </div>

      <div className="w-full mt-[23px] flex flex-col">
        <p className="text-xs">
          بالضغط على التالي، فهذا يعني الموافقة على{" "}
          <Link to="/terms" className="underline">
            الشروط و الاحكام
          </Link>
        </p>
      </div>
    </>
  );
}

export default SignupForm;
