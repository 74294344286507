import React from "react";
import WhatsappIcon from "../../Assets/Icons/whatsapp.png";
import PhoneIcon from "../../Assets/Icons/phone.png";
import { Number } from "../ContactUS";

const mapNumberTypeToIcon = {
  whatsapp: WhatsappIcon,
  phone: PhoneIcon,
};

const RenderNumbers = ({ number }: { number: Number }) => {
  return (
    <div className="flex justify-between items-center border-[1px] p-[20px] gap-[10px] rounded-[20px]  border-gold">
      <span className="font-sans text-[18px] font-[600]">
        {number.number.replace("+20", "0")}
      </span>

      <div className="flex gap-[18px]">
        {number.methods.map((method) => {
          const link =
            method === "whatsapp"
              ? `https://wa.me/${number.number}`
              : `tel:${number.number}`;

          return (
            <a href={link} target="_blank" rel="noreferrer">
              <img
                src={mapNumberTypeToIcon[method]}
                alt={method}
                className="h-[30px] w-[30px] object-contain"
              />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default RenderNumbers;
